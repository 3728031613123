// Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';

// Bootstrap icons
@import '~bootstrap-icons/font/bootstrap-icons';

// FontAwesome 5 Pro
@import '~@fortawesome/fontawesome-pro/css/all.css';

// jQuery datepicker
@import '~jquery-ui/themes/base/all.css';

// Toastr
@import 'node_modules/toastr/toastr';
